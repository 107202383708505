import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: store.getters['env']?.VUE_APP_ROUTER_PREFIX || 'web',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/Index.vue'),
      meta: { title: 'Home' },
    },
    {
      path: '/terms-conditions',
      name: 'terms-conditions',
      component: () => import(/* webpackChunkName: "terms-condition" */ './views/Legal/TermsConditions.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import(/* webpackChunkName: "privacy-policy" */ './views/Legal/PrivacyPolicy.vue'),
    },
    {
      path: '/dpa',
      name: 'dpa',
      component: () => import(/* webpackChunkName: "dpa" */ './views/Legal/DPA.vue'),
    },
    {
      path: '/security-declaration',
      name: 'security-declaration',
      component: () => import(/* webpackChunkName: "security-declaration" */ './views/Legal/SecurityDeclaration.vue'),
    },
    {
      path: '/get-app',
      name: 'get-app',
      component: () => import(/* webpackChunkName: "get-app" */ './views/GetApp/GetApp.vue'),
    },
    {
      path: '/parking/invitation',
      name: 'parking.invitation',
      component: () => import(/* webpackChunkName: "get-app" */ './views/Parking/Invitation.vue'),
    },
    {
      path: '/parking/management',
      name: 'parking.management',
      component: () => import(/* webpackChunkName: "get-app" */ './views/Parking/Management.vue'),
    },
    {
      path: '/verification/guestbook/:type?',
      name: 'verification.guestbook',
      component: () =>
        import(/* webpackChunkName: "guestbook-verification" */ './views/Verification/VerificationGuestbookView.vue'),
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "get-app" */ './views/Error/NotFound.vue'),
    },
    {
      path: '/redirect',
      name: 'redirect',
      component: () => import(/* webpackChunkName: "redirect" */ './views/GetApp/GetApp.vue'),
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.afterEach(to => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (
    to.name === 'redirect' &&
    (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i) || userAgent.match(/Android/i))
  ) {
    const query = to.query
    const module = query.module ? query.module : null
    const id = query.id ? query.id : null
    let url = `${store.getters['env'].VUE_APP_APPLICATION_URL}`

    if (module) {
      url += `${module}`
    }
    if (id) {
      url += `/${id}`
    }
    window.location = url
  }
})

export default router
